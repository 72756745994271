import { BASE_URL, PROFINANSY_URL, SESSION_URL } from "../config";
import { addToast } from "../fetchWrapper/toast";
import {
  deleteHook,
  getHook,
  postHook,
  putHook,
} from "../fetchWrapper/useAxios";
import { getDataList } from "./dataList";

const initialState = {
  data: [],
  signIn: false,
  error: null,
  accessToken: localStorage.getItem("accessToken"),
  loading: false,
  refreshToken: localStorage.getItem("refreshToken"),
  isProfinansy: false,
  noAccess: false,
  isTariff: false,
};

const userData = (state = initialState, action) => {
  switch (action.type) {
    case "userData/signin/pending":
      return { ...state, loading: true, signIn: false, error: null };
    case "userData/signin/fulfilled":
      return {
        ...state,
        loading: false,
        signIn: true,
        accessToken: action.payload.token,
      };
    case "userData/signin/rejected":
      return { ...state, signIn: false, loading: false, error: action.error };
    case "userData/session/pending":
      return { ...state, loading: true, error: null };
    case "userData/session/fulfilled":
      return {
        ...state,
        accessToken: action.payload.token,
        rights: action.payload.rights,
        isProfinansy: true,
      };
    case "userData/session/rejected":
      return { ...state, loading: false, error: action.error };
    case "userData/userProfinansy/pending":
      return { ...state, loading: true, error: null };
    case "userData/userProfinansy/fulfilled":
      let data = action.payload;
      return {
        ...state,
        user: { ...state.user, ...data },
      };
    case "userData/userProfinansy/rejected":
      return { ...state, loading: false, error: action.error };
    case "userData/refreshToken/fulfilled":
      return {
        ...state,
        loading: false,
        signIn: true,
        accessToken: action.payload.access_token,
      };
    case "userData/refreshToken/rejected":
      return { ...state, signIn: false, loading: false, error: action.error };

    case "userData/user/pending":
      return { ...state, loading: true, error: null };
    case "userData/user/fulfilled":
      return { ...state, loading: false, user: action.payload };
    case "userData/user/rejected":
      return { ...state, error: action.error };

    case "experts/load/pending":
      return { ...state, loading: true, error: null };
    case "experts/load/fulfilled":
      return { ...state, loading: false, data: action.payload };
    case "experts/load/rejected":
      return { ...state, error: action.error };

    case "user/access/pending":
      return { ...state };
    case "user/access/fulfilled":
      return { ...state, noAccess: action.payload };
    case "user/tariff/pending":
      return { ...state };
    case "user/tariff/fulfilled":
      return { ...state, isTariff: action.payload };

    case "user/avatar/pending":
      return { ...state };
    case "user/avatar/fulfilled":
      return { ...state, user: { ...state.user, avatar: action.payload } };

    case "data/update/pending":
      return { ...state, loading: true, success: null };
    case "data/update/fulfilled":
      return { ...state, loading: false, user: action.payload, success: true };

    case "restore/pending":
      return { ...state, loading: true, error: null };
    case "restore/fulfilled":
      return { ...state, loading: false, data: action.payload, success: true };
    case "restore/rejected":
      return { ...state, error: action.error };
    case "renew/pending":
      return { ...state, loading: true, error: null };
    case "renew/fulfilled":
      return { ...state, loading: false, success: true };
    case "renew/rejected":
      return { ...state, error: action.error };

    default:
      return state;
  }
};

export const handleTariff = (data) => async (dispatch) => {
  dispatch({ type: "user/tariff/pending" });
  dispatch({ type: "user/tariff/fulfilled", payload: data });
};
export const handleAccess = (data) => async (dispatch) => {
  dispatch({ type: "user/access/pending" });
  dispatch({ type: "user/access/fulfilled", payload: data });
};

export const auth = (data) => async (dispatch) => {
  dispatch({ type: "userData/signin/pending" });
  const formData = new FormData();
  formData.append("username", data.login);
  formData.append("password", data.password);
  const response = await postHook(dispatch, "/auth/sign-in/", formData);
  if (response?.detail) {
    dispatch({ type: "userData/signin/rejected", error: response?.detail });
  } else {
    localStorage.setItem("accessToken", response.access_token);
    localStorage.setItem("refreshToken", response.refresh_token);
    dispatch({ type: "userData/signin/fulfilled", payload: response });
  }
};
export const getSession = () => async (dispatch) => {
  try {
    dispatch({ type: "userData/session/pending" });
    const response = await fetch(`${SESSION_URL}/auth/session?type=web`, {
      credentials: "include",
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    const json = await response.json();
    if (json.authorized) {
      dispatch({ type: "userData/session/fulfilled", payload: json });
      localStorage.setItem("accessToken", json.token);
    } else {
      dispatch({ type: "userData/session/rejected", error: response });
      window.location.assign(`${PROFINANSY_URL}/login?redirect=${BASE_URL}`);
    }
  } catch (e) {
    dispatch({ type: "userData/session/rejected", error: e.message });
  }
};
export const getUser = () => async (dispatch, getState) => {
  const state = getState();
  try {
    dispatch({ type: "userData/userProfinansy/pending" });
    const response = await fetch(`${SESSION_URL}/auth/user`, {
      credentials: "include",
      method: "GET",
      headers: {
        Accept: "application/json",
        Token: state.userData.accessToken,
      },
    });
    const json = await response.json();
    if (response.status !== 200) {
      dispatch({ type: "userData/userProfinansy/rejected", error: response });
    } else {
      let email, phone, fullname;
      json?.account_list?.map(({ acc_type, login }) => {
        if (acc_type === "email") {
          email = login;
        } else if (acc_type === "phone") {
          phone = login;
        }
        return email && phone;
      });
      fullname = `${json?.last_name} ${json?.name}`;
      dispatch({
        type: "userData/userProfinansy/fulfilled",
        payload: {
          email: email || "",
          phone: phone || "",
          fullname: fullname || "",
          id: json?.user_id,
        },
      });
    }
  } catch (e) {
    // dispatch({ type: "userData/user/rejected", error: e.message });
  }
};
export const userLogOut = () => async (dispatch, getState) => {
  try {
    const response = await fetch(`${SESSION_URL}/auth/logout`, {
      credentials: "include",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    const json = await response.json();
    if (json?.ok) {
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("expireTime");
      window.location.assign("/");
    }
  } catch (e) {
    return e;
  }
};
export const getUserData = () => async (dispatch, getState) => {
  dispatch({ type: "userData/user/pending" });
  const response = await getHook(dispatch, "/users/get_me_data");
  if (response?.detail) {
    dispatch({ type: "userData/user/rejected", error: response?.detail });
  } else {
    dispatch({ type: "userData/user/fulfilled", payload: response });
    dispatch(getDataList());

    // if (!response?.email || !response.phone || !response?.fullname) {
    //   dispatch(getUser());
    // }
  }
};

export const addImage = (e) => async (dispatch) => {
  dispatch({ type: "user/avatar/pending" });
  const { files } = e?.target;
  const data = new FormData();
  data.append("file", files[0]);
  const response = await postHook(dispatch, "/users/avatar", data);
  if (!response?.detail) {
    dispatch({
      type: "user/avatar/fulfilled",
      payload: response?.filepath,
    });
    dispatch(addToast({ text: "Вы загрузили фото профиля", type: "success" }));
  }
};

export const deleteImage = () => async (dispatch, getState) => {
  const state = getState();
  const userData = state.userData?.user;
  dispatch({ type: "userData/user/pending" });
  const response = await deleteHook(dispatch, "/users/avatar");
  if (!response?.detail) {
    dispatch({
      type: "userData/user/fulfilled",
      payload: { ...userData, ...response },
    });
    dispatch(addToast({ text: "Вы удалили фото профиля", type: "success" }));
  }
};

export const addImageExpert = (e, id) => async (dispatch) => {
  dispatch({ type: "user/avatar/pending" });
  const { files } = e.target;
  const data = new FormData();
  data.append("file", files[0]);
  const response = await postHook(
    dispatch,
    `/users/user_avatar?id=${id}`,
    data
  );
  dispatch({ type: "user/avatar/fulfilled", payload: response });
};

export const updateDataUser =
  (
    avatar,
    fullname,
    firstname,
    lastname,
    surname,
    email,
    phone,
    country,
    currency,
    investor_status,
    status,
    comment
  ) =>
  async (dispatch, getState) => {
    const state = getState();
    const userData = state?.userData?.user;
    dispatch({ type: "data/update/pending" });
    const response = await putHook(dispatch, "/users/user_data_update", {
      avatar: state.userData.user.avatar,
      fullname,
      firstname,
      lastname,
      surname,
      email,
      phone,
      country,
      currency,
      investor_status,
      status,
      comment,
    });
    if (response?.detail) {
      dispatch({ type: "data/update/rejected", error: response?.detail });
    } else {
      delete response?.available_courses;
      dispatch({
        type: "data/update/fulfilled",
        payload: { ...userData, ...response },
      });
      dispatch(addToast({ text: "Данные сохранены", type: "success" }));
    }
  };

export const getAllExpert = () => async (dispatch) => {
  dispatch({ type: "experts/load/pending" });
  const response = await getHook(dispatch, "/expert/experts");
  if (response?.detail) {
    dispatch({ type: "experts/load/rejected", error: response?.detail });
  }
  dispatch({ type: "experts/load/fulfilled", payload: response });
};

export const restorePassword = (data) => async (dispatch) => {
  let formData = new FormData();
  formData.append("email", data.email);
  dispatch({ type: "restore/pending" });
  const response = await postHook(
    dispatch,
    "/auth/password-recovery",
    formData
  );
  if (response?.detail) {
    dispatch({ type: "restore/rejected", error: response?.detail });
  } else {
    dispatch({ type: "restore/fulfilled", payload: response });
  }
};
export const changePassword = (data) => async (dispatch, getState) => {
  dispatch({ type: "renew/pending" });
  const response = await postHook(dispatch, "/auth/new-password", data);
  if (response?.detail) {
    dispatch({ type: "renew/rejected", error: response?.detail });
  } else {
    localStorage.setItem("accessToken", response);
    dispatch({ type: "renew/fulfilled", payload: response });
  }
};

export default userData;
