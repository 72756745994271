import React, { useEffect, Suspense } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { getSession, getUserData } from "../redux/features/userData";
import Loader from "./loader/loader";
import Toasts from "./toast/Toasts";
import parseJwt from "../utils/parseJwtToken";

const Welcome = React.lazy(() => import("./auth/WelcomePage"));
const PrivateRoutes = React.lazy(() => import("./RouteGuard/PrivateRoutes"));

function App() {
  const dispatch = useDispatch();

  const { accessToken, user } = useSelector((state) => state.userData);
  const toasts = useSelector((state) => state.toasts);

  const accessTokenStorage = localStorage.getItem("accessToken");

  const { tariff } = !!accessTokenStorage && parseJwt(accessTokenStorage); //!!accessToken &&

  const hasAccessPlanAccounts =
    (!!tariff &&
      tariff?.some((el) => el?.t === 10123 && el?.p?.includes(96))) ||
    tariff?.some((el) => el?.t === 10167);

  const hasAccess =
    !!user &&
    (user?.available_courses?.length === 0
      ? hasAccessPlanAccounts
      : user?.available_courses?.length > 0);

  const isStudent = !!user && user?.role === "STUDENT";

  useEffect(() => {}, [dispatch, user]);

  useEffect(() => {
    if (!user) {
      dispatch(getSession());
      if (accessToken) {
        dispatch(getUserData());
      }
    }
  }, [dispatch, accessToken, user]);

  return (
    <div>
      <Suspense
        fallback={
          <div className="loader">
            <Loader />
          </div>
        }
      >
        {isStudent ? (
          hasAccess ? (
            <PrivateRoutes />
          ) : (
            <Routes>
              <Route path="/" element={<Welcome />} />
            </Routes>
          )
        ) : (
          <PrivateRoutes />
        )}
        <Toasts toasts={toasts} />
      </Suspense>
    </div>
  );
}

export default App;
